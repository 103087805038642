<template>
    <interior-page-content-container
      nav-selection="calculators"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      backButtonText="Calculators"
    >

      <template #title>
        Downsizing
      </template>

      <template #content>
        <div class="`
            flex flex-col items-center self-center justify-center flex-shrink-0
            pb-4 mb-10 mx-7 w-72 md:w-96 pt-2
            `"
        >

            <div class="w-full mb-3 text-2xl font-bold text-left text-content old-home-total">
                Old Home: {{format(oldHouseTotal)}}
            </div>

            <text-input-tool-tip
              v-for="input in oldHouse" :key="input.label + 'old-home'"
              :label="input.label"
              :input.sync="input.value"
              :tool-tip="input.toolTip"
              :links="input.links"
              type="number"
              class="mb-4"
            />

            <div class="w-full mb-3 text-2xl font-bold text-left text-content new-home-total">
                New Home: {{format(newHouseTotal)}}
            </div>

            <text-input-tool-tip
                v-for="input in newHouse" :key="input.label + 'new-home'"
                :label="input.label"
                :input.sync="input.value"
                :tool-tip="input.toolTip"
                :links="input.links"
                type="number"
                class="mb-4"
            />

            <div class="w-full mb-3 text-2xl font-bold text-left text-content other-total">
                Other: {{format(extraTotal)}}
            </div>

            <text-input-tool-tip
                v-for="input in extra" :key="input.label + 'new-home'"
                :label="input.label"
                :input.sync="input.value"
                :tool-tip="input.toolTip"
                :links="input.links"
                type="number"
                class="mb-4"
            />

            <p class="pb-5 text-left text-content">
                The total costs for downsizing result in the number shown below
            </p>

            <p class="mb-10 text-2xl text-content total">
                {{format(total)}}
            </p>

        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import TextInputToolTip from '@/components/shared/inputs/TextInputToolTip.vue';

import * as calculatorsInfo from '@/assets/json/calculators.json';

export default {
  name: 'CalculatorDownsizing',
  components: {
    InteriorPageContentContainer,
    TextInputToolTip,
  },
  data() {
    return {
      oldHouse: {},
      newHouse: {},
      extra: {},
    };
  },
  computed: {
    oldHouseTotal() {
      const oldHouseValue = this.strToNum(this.oldHouse[0].value);
      const realEstateCommision = this.strToNum(this.oldHouse[1].value);
      const salesTax = this.strToNum(this.oldHouse[2].value);
      const salseComission = this.strToNum(this.oldHouse[3].value);
      const legalFees = this.strToNum(this.oldHouse[4].value);
      const renovations = this.strToNum(this.oldHouse[5].value);

      return -oldHouseValue * ((realEstateCommision + salesTax + salseComission) / 100)
      - legalFees - renovations;
    },
    newHouseTotal() {
      return -this.newHouse.reduce((pastVal, curVal) => pastVal + this.strToNum(curVal.value), 0);
    },
    extraTotal() {
      return -this.extra.reduce((pastVal, curVal) => pastVal + this.strToNum(curVal.value), 0);
    },
    total() {
      return this.oldHouseTotal + this.newHouseTotal + this.extraTotal;
    },
  },
  created() {
    this.oldHouse = calculatorsInfo.downsizing.oldHouse;
    this.newHouse = calculatorsInfo.downsizing.newHouse;
    this.extra = calculatorsInfo.downsizing.extra;
  },
  methods: {
    format(num) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
    },
    strToNum(str) {
      if (str === '') return 0;
      return parseFloat(str);
    },
    backNav() {
      this.$router.push({ name: 'Calculators' });
    },
  },
};
</script>

<style>

</style>
